import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import Scrollbar from 'smooth-scrollbar';

const scrollbar = Scrollbar.init(document.querySelector('#main-scrollbar'));

gsap.registerPlugin(Flip, ScrollTrigger, MotionPathPlugin);

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    if (!sessionStorage.getItem('functionExecuted') && !document.querySelector('.simple-banner')) {
      // Your JavaScript function here
      initLoader();

      // Mark the function as executed in sessionStorage
      sessionStorage.setItem('functionExecuted', 'true');
    } else {
      let loaderOverlay = document.getElementById('loader-overlay');

      loaderOverlay.style.display = 'none';
      document.body.classList.remove('overflow-hidden');
      $('.header.top-header').css('opacity','1');
      $('.home-section.banner-section .section-inner-wrp').css('opacity','1');
      if (matchMedia('screen and (max-width: 992px)').matches) {
        $('.header.pop-up-header').css('opacity','1');
      }
      bannerCardFlip();
      textAnimation();
      setTimeout(() => {
        animateContainer();
      }, 1000);
    }
  },
};

$(window).on('resize', function () {
  checkElements();
});

$(window).on('load', function () {
  checkElements();
});

scrollbar.addListener(() => {
  if (matchMedia('screen and (min-width: 992px)').matches) {
    letStick();
  }

  checkElements()
});

function bannerCardFlip() {
  const $cards = $('.card-wrp-outer');
  let currentIndex = 0;
  let interval;
  let paused = false;
  const intervalDuration = 5000;
  let t = [];

  function showNextCard() {
    $cards.eq(currentIndex).removeClass('show');
    $cards.eq(currentIndex).children('span').removeClass('show-card');
    currentIndex = (currentIndex + 1) % $cards.length;
    $cards.eq(currentIndex).addClass('show');
    $cards.eq(currentIndex).children('span').addClass('show-card');

    bannerAnimate();
  }

  function bannerAnimate() {
    var $referenceElement = $('.yellow-shape-mirror');
    var referenceRect = $referenceElement[0].getBoundingClientRect();
    var referenceIconCenterX = referenceRect.left + (referenceRect.width / 2);
    var referenceIconCenterY = referenceRect.top + (referenceRect.height / 2);

    for (let i = 1; i < 7; i++) {
      if (t[i]) {
        t[i].kill(); // Kills the timeline and its tweens
      }

      t[i] = gsap.timeline();

      var $referenceIcon = $(`.icon-${i}.show-card`);
      var referenceIcon = $referenceIcon[0].getBoundingClientRect();

      var relativeDistanceX = referenceIconCenterX - referenceIcon.left;
      var relativeDistanceY = referenceIconCenterY - referenceIcon.top;

      if (i % 2 == 0) {
        t[i].fromTo(`.icon-${i}.show-card`,{
          opacity:0,
          x: relativeDistanceX,
          y: relativeDistanceY,
          scale: 0.7,
        },{
          x: 0,
          y: 0,
          duration: 1.1,
          scale: 1,
          ease: 'expo.inOut',
        }, 0);

        t[i]
        .to(`.icon-${i}.show-card`,
        {
          opacity: 1,
          duration: 1,
          ease: 'power3.inOut',
        }, 0.3)

        .to(`.icon-${i}.show-card`, {
          duration: 0.4,
          ease: 'circ.in',
          x: relativeDistanceX,
          y: relativeDistanceY,
          scale: 0.7,
          opacity: 0,
        }, 4.5)

        .to(`.icon-${i}.show-card` , {
          duration: 0.1,
          x: 0,
          y: 0,
          scale: 0.1,
          opacity: 0,
        }, 4.9);
      }

      else {
        t[i].fromTo(`.icon-${i}.show-card`,{
          opacity:0,
          x: relativeDistanceX,
          y: relativeDistanceY,
          scale: 0.7,
        },{
          x: 0,
          y: 0,
          duration: 1,
          scale: 1,
          ease: 'expo.inOut',
        }, 0);

        t[i]
        .to(`.icon-${i}.show-card`,
        {
          opacity: 1,
          duration: 0.5,
          ease: 'power3.inOut',
        }, 0.3)

        .to(`.icon-${i}.show-card`, {
          duration: 0.4,
          ease: 'circ.in',
          x: relativeDistanceX,
          y: relativeDistanceY,
          scale: 0.7,
          opacity: 0,
        }, 4.5)

        .to(`.icon-${i}.show-card` , {
          duration: 0.1,
          x: 0,
          y: 0,
          scale: 0.1,
          opacity: 0,
        }, 4.9);
      }
    }

    // Stagger animations to add a bit of delay between them
    gsap.timeline()
      .add(t[1])
      .add(t[2], 0.05)
      .add(t[3], 0)
      .add(t[4], 0.05)
      .add(t[5], 0)
      .add(t[6], 0.05);
  }

  function startFunction() {
    if (!paused) {
      interval = setInterval(() => {
        showNextCard();
      }, intervalDuration);
    } else {
      paused = false;
      resumeFunction();
    }
  }

  function pauseFunction() {
    clearInterval(interval);
    interval = null;
    paused = true;
  }

  function resumeFunction() {
    if (!interval) {
      interval = setInterval(() => {
        showNextCard();
      }, intervalDuration);
    }
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      pauseFunction();
    } else {
      resumeFunction();
    }
  }

  document.addEventListener('visibilitychange', handleVisibilityChange);
  startFunction();
  showNextCard();
}

function animateContainer() {
  const container = $('.intro-text-container');
  const items = $('.typing');
  let totalWidth = 40;

  let textTL = gsap.timeline();

  function activateSpan(item) {
    $(item).children('span').addClass('active');
  }

  items.each(function(index, item) {
    let itemWidth = $(item).outerWidth(true);
    totalWidth += itemWidth + 5;

    textTL.add(() => activateSpan(item), '+=0.5');

    textTL.to(container, {
      width: totalWidth,
      duration: 1.5,
      ease: 'power2.inOut',
      delay: 0.5,
    });
  });
}

function isElementCentered($element) {
  var elementTop = $element.offset().top;
  var elementHeight = $element.outerHeight();
  var elementCenter = elementTop + (elementHeight / 2);
  var viewportHeight = $(window).height();
  var viewportCenter = (viewportHeight / 2);

  return Math.abs(elementCenter - viewportCenter) < 200;
}

function checkElements() {
  if (matchMedia('screen and (max-width: 768px)').matches) {
    $('.work-inner-wrp .work-item').each(function() {
      if (isElementCentered($(this))) {
        $(this).addClass('centered');
      } else {
        $(this).removeClass('centered');
      }
    });
  }
}

function textAnimation() {
  const svgText = $('#animateText');
  const svgText2 = $('#animateText2');
  const svgText3 = $('#animateText3');
  var texttl = gsap.timeline();

  if (matchMedia('screen and (min-width: 1400px)').matches) {
    texttl.from(svgText, {
      opacity: 0,
      duration: 0.5,
      ease: 'none',
    }).to(svgText, {
      duration: 1,
      ease: 'none',
      opacity: 1,
    }).from(svgText, {
      attr: {
        startOffset: '-90%',
      },
      duration: 13.5,
      ease: 'none',
    }).to(svgText, {
      attr: {
        startOffset: '100%',
      },
      duration: 15,
      ease: 'none',
    });
  } else if(matchMedia('screen and (min-width: 1200px)').matches) {
    texttl.from(svgText2, {
      opacity: 0,
      duration: 0.5,
      ease: 'none',
    }).to(svgText2, {
      duration: 1,
      ease: 'none',
      opacity: 1,
    }).from(svgText2, {
      attr: {
        startOffset: '-125%',
      },
      duration: 18.75,
      ease: 'none',
    }).to(svgText2, {
      attr: {
        startOffset: '100%',
      },
      duration: 15,
      ease: 'none',
    });
  } else {
    texttl.from(svgText3, {
      opacity: 0,
      duration: 0.5,
      ease: 'none',
    }).to(svgText3, {
      duration: 1,
      ease: 'none',
      opacity: 1,
    }).from(svgText3, {
      attr: {
        startOffset: '-170%',
      },
      duration: 20.4,
      ease: 'none',
    }).to(svgText3, {
      attr: {
        startOffset: '100%',
      },
      duration: 12,
      ease: 'none',
    });
  }
  texttl.repeat(-1);
}

function initLoader() {
  let loaderOverlay = document.getElementById('loader-overlay');
  let percentageText = document.getElementById('percentage');
  let progressBar = document.getElementById('progress-bar-component');
  let assetsLoaded = 0;
  let totalAssets = document.images.length;
  let maxNaturalPercentage = 80; // We'll load assets up to 80%

  // Fade in loaderOverlay at the beginning
  gsap.to(loaderOverlay, {
    opacity: 1,
    duration: 0.1,
    ease: 'power1.out',
  });

  function updateLoader() {
    assetsLoaded++;
    let percent = Math.min(Math.round((assetsLoaded / totalAssets) * 100), maxNaturalPercentage); // Cap at 80%

    gsap.to(percentageText, {
      textContent: percent,
      duration: 0.5,
      snap: { textContent: 1 },
      ease: 'power1.out',
    });

    gsap.to(progressBar, {
      width: percent + '%',
      duration: 0.5,
      ease: 'power1.out',
    });

    if (assetsLoaded === totalAssets) {
      gsap.delayedCall(2, () => {
        gsap.to(percentageText, {
          textContent: 100,
          duration: 1,
          snap: { textContent: 1 },
          ease: 'power1.out',
        });

        gsap.to(progressBar, {
          width: '100%',
          duration: 1,
          ease: 'power1.out',
        });

        gsap.to(loaderOverlay, {
          opacity: 0,       // Fade out loader overlay
          duration: 1,
          delay: 0.5,
          onComplete: () => {
            loaderOverlay.style.display = 'none';
            document.body.classList.remove('overflow-hidden');
            $('.header.top-header').css('opacity','1');
            $('.home-section.banner-section .section-inner-wrp').css('opacity','1');
            if (matchMedia('screen and (max-width: 992px)').matches) {
              $('.header.pop-up-header').css('opacity','1');
            }
            bannerCardFlip();
            textAnimation();
            setTimeout(() => {
              animateContainer();
            }, 1000);
          },
        });
      });
    }
  }

  Array.from(document.images).forEach((img) => {
    if (img.complete) {
      updateLoader();
    } else {
      img.addEventListener('load', updateLoader);
    }
  });
}

function letStick() {
  if($('#sticky-item-parent').length && $('#sticky-item-parent').length) {
    const parentElementTopPosition = $('#sticky-item-parent').offset().top;
    const parentElementHeight = $('#sticky-item-parent').outerHeight();
    const elementHeight = $('#sticky-item').outerHeight();
    const maxTranslateValue = parentElementHeight - elementHeight;

    if(parentElementTopPosition < 0) {
      const translateValue = parentElementTopPosition*(-1);

      if(0 < translateValue && translateValue < maxTranslateValue) {
        $('#sticky-item').css('transform', `translateY(${translateValue}px)`);
      } else {
        $('#sticky-item').css('transform', `translateY(${maxTranslateValue}px)`);
      }
    } else {
      $('#sticky-item').css('transform', 'translateY(0px)');
    }
  }
}
